body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: black;
}

* {
    box-sizing: border-box;
}

@media (min-width: 600px) {
    .MuiAutocomplete-option {
        min-height: 48px !important;
    }
}

*:focus {
    outline: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root,
#root > div {
    height: 100%;
    margin: 0;
    background: #ffffff;
    color: black;
}

*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 20px;
    border: 2px solid #f0f0f0;
}
